import React from "react";

interface ErrorFallbackProps {
  message?: string;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
      <h1 className="text-3xl font-semibold text-gray-900 dark:text-white mb-4">
        Something went wrong
      </h1>
      <p className="text-base text-gray-700 dark:text-gray-400 mb-6 text-center max-w-md">
        {message ||
          "An unexpected error has occurred. Please try refreshing the page or come back later."}
      </p>
      <button
        onClick={() => window.location.reload()}
        className="px-4 py-2 bg-black dark:bg-white text-white dark:text-black rounded-md hover:bg-gray-900 transition"
      >
        Return Home
      </button>
    </div>
  );
};

export default ErrorFallback;
