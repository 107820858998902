import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import { useFormContext, useWatch } from "react-hook-form";
import useSourcesStore from "@/stores/sources.store";
import { NetworkItem } from "@/types/sources.type";
import IconMapper from "./IconMapper";
import { Key, useEffect, useState } from "react";
import { PencilIcon, X, CheckIcon } from "lucide-react";

export function StepSelectSource() {
  const networks = useSourcesStore((state) => state.networks);
  const sources = useSourcesStore((state) => state.sources);
  const selectedSourceGroup = useSourcesStore(
    (state) => state.selectedSourceGroup
  );
  const editSourceGroup = useSourcesStore((state) => state.editSourceGroup);
  const sourceTypes = networks.ENTRY_TYPES;
  const { control, setValue, watch } = useFormContext();

  const selectedSourceType = useWatch({
    control,
    name: "groupType",
  });

  const sourceName = watch("sourceName");

  const [isEditing, setIsEditing] = useState(!selectedSourceGroup); // Default to editing if no selectedSourceGroup

  useEffect(() => {
    if (selectedSourceGroup && selectedSourceGroup?.groupType) {
      setValue("groupType", selectedSourceGroup.groupType,
        {
        shouldValidate: true,
      });
    }
    if (selectedSourceGroup && selectedSourceGroup?.sourceName) {
      setValue("sourceName", selectedSourceGroup.sourceName, 
        {
        shouldValidate: true,
      });
    }
  }, [selectedSourceGroup, setValue]);

  const isGroupTypeDisabled = sources && sources.length > 0;

  const handleRenameSubmit = async () => {
    if (
      selectedSourceGroup?.sourceGroupId &&
      sourceName !== selectedSourceGroup.sourceName
    ) {
      await editSourceGroup(selectedSourceGroup.sourceGroupId, sourceName);
      setIsEditing(false);
    }
  };

  const handleCancelEdit = () => {
    setValue("sourceName", selectedSourceGroup?.sourceName || "");
    setIsEditing(false);
  };

  return (
    <FormField
      control={control}
      name="groupType"
      render={({ field }) => (
        <FormItem className="lg:flex lg:flex-col lg:gap-5">
          <FormControl className="lg:grid lg:h-72 lg:grid-cols-3 mb-8">
            <RadioGroup
              onValueChange={field.onChange}
              value={field.value}
              disabled={isGroupTypeDisabled}
            >
              {sourceTypes.map(
                (sourceType: NetworkItem, i: Key | null | undefined) => (
                  <FormItem key={i}>
                    <FormControl>
                      <RadioGroupItem
                        value={sourceType.symbol}
                        className="peer sr-only"
                        disabled={isGroupTypeDisabled}
                      />
                    </FormControl>
                    <FormLabel
                      className={`text-sm border-2 dark:border-gray-700 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex flex-col items-center justify-between rounded-md border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-muted-foreground [&:has([data-state=checked])]:border-primary ${
                        isGroupTypeDisabled
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      <IconMapper type={sourceType.symbol} />
                      <div className="flex flex-col font-medium ">
                        <p className="text-lg dark:text-white">
                          {sourceType.name}
                        </p>
                      </div>
                    </FormLabel>
                  </FormItem>
                )
              )}
            </RadioGroup>
          </FormControl>

          {selectedSourceType && (
            <>
              <div
                data-orientation="horizontal"
                role="none"
                className="shrink-0 bg-border h-[1px] w-full my-4"
              />
              <div className="flex items-center justify-center rounded-sm p-2 w-full lg:w-96">
                <FormField
                  control={control}
                  name="sourceName"
                  rules={{ required: "Source Name is required" }}
                  render={({ field }) => (
                    <FormItem className="w-full lg:w-96">
                      <FormLabel>Source Name</FormLabel>
                      <FormControl>
                        {isEditing ? (
                          <div className="flex items-center gap-2">
                            <Input {...field} value={field.value} />
                            {selectedSourceGroup ? (
                              <>
                                <CheckIcon
                                  className="h-6 w-6 cursor-pointer text-green-500"
                                  onClick={handleRenameSubmit}
                                />
                                <X
                                  className="h-6 w-6 cursor-pointer text-red-500"
                                  onClick={handleCancelEdit}
                                />
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="flex items-center justify-between rounded-lg bg-muted p-1 text-muted-foreground">
                            <span className="inline-flex h-9 items-center mx-2">
                              {sourceName}
                            </span>
                            <PencilIcon
                              className="h-5 w-5 cursor-pointer"
                              onClick={() => setIsEditing(true)}
                            />
                          </div>
                        )}
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </>
          )}
        </FormItem>
      )}
    />
  );
}
