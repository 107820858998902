/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import React, { useEffect, useState } from "react";

import { Input } from "@/components/ui/input";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

interface NetworkOptionCardProps {
  network: {
    value: string;
    img: string;
    label: string;
  };
  onSelect: (value: string) => void;
}

interface SelectNetworkDialogProps {
  networks: any[];
  onValueChange: (value: string) => void;
  selectedValue: string | null;
  title: string;
  description: string;
  name: string | undefined;
}

const SelectNetworkDialog = ({
  networks,
  onValueChange,
  selectedValue,
  title,
  description,
  name
}: SelectNetworkDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredNetworks, setFilteredNetworks] = useState(networks);

  const handleSelect = (value: string) => {
    onValueChange(value);
    setDialogOpen(false);
  };

  useEffect(() => {
    const filtered = networks.filter((network) =>
      network?.label?.toLowerCase().includes(searchValue?.toLowerCase())
    );

    setFilteredNetworks(filtered);
  }, [networks, searchValue]);

  useEffect(() => {
    if (dialogOpen) {
      setSearchValue(""); 
    }
  }, [dialogOpen]);

  const selectedNetwork = networks.find((n) => n.value === selectedValue);  
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <button onClick={() => setDialogOpen(true)} className="w-full" name={name}>
          {selectedValue ? (
            <NetworkOptionCard
              network={selectedNetwork}
              onSelect={() => {}}
            />
          ) : (
            <div className="inline-block p-2 m-1 border-2 dark:border-gray-700 dark:hover:border-blue-300  rounded-lg cursor-pointer hover:border-blue-500 w-full">
              {title || "Select network"}
            </div>
          )}
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{title || "Select Network"}</DialogTitle>
        <DialogDescription>
          {description || "Choose one of the available networks."}
        </DialogDescription>
        <Input
          className="w-full mt-2"
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div className="max-h-[34rem] mt-2 overflow-y-auto">
          <div className="grid grid-cols-4 gap-2 p-2">
            {filteredNetworks.map((network: any, index: number) => (
              <NetworkOptionCard
                key={`${network.value}-${index}`}
                network={network}
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const NetworkOptionCard = React.forwardRef<
  HTMLDivElement,
  NetworkOptionCardProps
>(({ network, onSelect }, ref) => {
  const imgSrc = network?.img ? network.img : "/unknown.png";

  const truncateLabel = (label: string, maxLength: number) => {
    if (!label) return "Unknown";
    const firstSpaceIndex = label.indexOf(' ');
    if (firstSpaceIndex !== -1 && firstSpaceIndex <= maxLength) {
      return label.slice(0, firstSpaceIndex) + '...';
    }
    return label.length > maxLength ? label.slice(0, maxLength) + '...' : label;
  };

  const truncatedLabel = network?.label ? truncateLabel(network.label, 8) : "Unknown";

  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className="inline-block p-2 m-1 w-24 border-2 dark:border-gray-700 dark:hover:border-gray-400 cursor-pointer rounded-lg hover:border-gray-500"
      onClick={() => onSelect(network.value)}
      role="option"
      aria-selected="false"
      tabIndex={0}
    >
      <Avatar className="block mx-auto h-12 w-12 bg-gray-200">
        <AvatarImage src={imgSrc} alt={`${network?.label || "Unknown"} logo`} />
        <AvatarFallback>{network?.label?.charAt(0) || "?"}</AvatarFallback>
      </Avatar>

      <div className="text-center text-sm">
        <span className="cursor-pointer" title={network?.label || "Unknown"}>
          {truncatedLabel}
        </span>
      </div>
    </div>
  );
});



// interface Network {
//   value: string;
//   img: string;
//   label: string;
// }

interface NetworkSelectProps {
  networks: any[];
  onValueChange: (value: string) => void;
  title: string;
  description: string;
  initialValue: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  name?: string;
}

const NetworkSelect = ({
  networks,
  onValueChange,
  title,
  description,
  initialValue,
  value,
  onChange,
  onBlur,
  name,
}: NetworkSelectProps) => {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(
    value || initialValue
  );


  useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
    }
  }, [value]);

const handleSelect = (newValue: string) => {
    setSelectedValue(newValue);
    onValueChange(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (onBlur) {
      onBlur();
    }
  };


  return (
    <SelectNetworkDialog
      networks={networks}
      onValueChange={handleSelect}
      selectedValue={selectedValue}
      title={title}
      description={description}
      name={name}
    />
  );
};

export default NetworkSelect;

