import React from "react";
import {
  WalletMinimal,
  Fingerprint,
  Coins,
  ChartCandlestick,
  CircleGauge,
  LucideAreaChart,
  ListTree,
} from "lucide-react";

interface IconProps {
  type: string;
}

const IconMapper: React.FC<IconProps> = ({ type }) => {
  const getIcon = () => {
    switch (type.toUpperCase()) {
      case "BALA":
        return <WalletMinimal className="lg:size-12" />;
      case "SIGN":
        return <Fingerprint className="lg:size-12" />;
      case "DEFI":
        return <Coins className="lg:size-12" />;
      case "EXCH":
        return <ChartCandlestick className="lg:size-12" />;
      case "RISK":
        return <CircleGauge className="lg:size-12" />;
      case "PUBK":
        return <ListTree className="lg:size-12" />;
      default:
        return <LucideAreaChart className="lg:size-12" />;
    }
  };

  return <div>{getIcon()}</div>;
};

export default IconMapper;
