import { Check } from "lucide-react"; // Assuming you're using lucide-react icons for the tick mark
import useSourcesStore from "@/stores/sources.store";

interface StepItemProps {
  infos: {
    num: number;
    title: string;
    description: string;
  };
}

export function StepItem({ infos }: StepItemProps) {
  const { step } = useSourcesStore();
  const maxStep = 5;
  const isFinished = step === maxStep && infos.num === maxStep - 1;
  const isCompleted = step > infos.num; // Check if this step is completed
  const isActive = step === infos.num || isFinished;

  return (
    <div className="relative z-10 flex flex-col items-center">
      <span
        className={`flex w-10 h-10 lg:w-12 lg:h-12 items-center justify-center rounded-full border-4 font-bold dark:text-black transition-all duration-300 ${
          isActive || isCompleted
            ? "border-black  bg-gray-950 text-white dark:bg-gray-200 dark:border-gray-300"
            : "border-gray-400 dark:border-gray-700 bg-white dark:bg-gray-800 text-black dark:text-white"
        }`}
      >
        {isCompleted ? (
          <Check className="text-white dark:text-black" /> // Show tick for completed steps
        ) : (
          infos.num // Show the step number for active or upcoming steps
        )}
      </span>

      {/* Step Info */}
      <div className="text-center mt-2">
        <p className="text-sm text-black dark:text-white">{infos.title}</p>
      </div>
    </div>
  );
}