/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useEffect } from "react";
import { SOURCE_PAGE_DESCRIPTION, SOURCE_PAGE_HEADING } from "@/config/general";
import {
  FileSliders,
  Cog,
  Database,
  Pencil,
  Upload,
  Download,
} from "lucide-react";
import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { SourceModal } from "@/components/dialog/SourcesModal";
import DataTable from "@/components/table/CustomDataTable";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import { downloadSourceGroup } from "@/services/sourceService";
import useSourcesStore, {
  usePagination,
  useSourceGroups,
} from "@/stores/sources.store";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { RelativeDateTime } from "@/components/misc/RelativeDateTime";

const Sources: React.FC = () => {
  const navigate = useNavigate();
  const {
    loading,
    clearSourceEntries,
    setSelectedSourceGroupData,
    fetchAllSourceGroups,
    setCurrentPage,
    setPageSize,
  } = useSourcesStore();

  const sourceGroups = useSourceGroups();

  const { currentPage, pageSize, totalPages, totalRows } = usePagination();

  const [sourceOverlayIsOpen, setSourceOverlayIsOpen] = React.useState(false);
  const [editSourceId, setEditSourceId] = React.useState<string | null>(null);

  useEffect(() => {
    fetchAllSourceGroups(currentPage, pageSize);
  }, [pageSize, currentPage, fetchAllSourceGroups]);

  const validateAndCleanCSV = useCallback((csvData: string): string => {
    let cleanedData: any[] = [];
    const requiredColumns: string[] = ["ADDRESS", "NETWORK", "ASSET"];

    Papa.parse(csvData, {
      header: true,
      complete: (results) => {
        cleanedData = results.data
          .map((row: any) => {
            const cleanedRow: any = {};
            requiredColumns.forEach((col) => {
              cleanedRow[col] = row[col] || "";
            });
            return cleanedRow;
          })
          .filter((row: any) => row.ADDRESS !== "");
      },
    });

    return Papa.unparse(cleanedData);
  }, []);

  const cols = useMemo(
    () => [
      {
        id: "sourceName",
        name: "Name",
        width: 250,

        sortable: true,
      },
      {
        id: "groupType",
        name: "Type",
        width: 200,
        sortable: true,
        cell: (value: string) => getNameBySymbol(value),
      },
      { id: "count", name: "Number of Entries", width: 100 },
      {
        id: "updated",
        name: "Last Updated",
        width: 200,
        cell: (value: number) => <RelativeDateTime value={value} />,
        sortable: true,
      },
    ],
    []
  );

  const actionButtons = useMemo(
    () => [
      {
        onClick: () => {
          setSelectedSourceGroupData(null);
          clearSourceEntries();
          setEditSourceId(null);
          navigate(`/sources/step`);
        },
        text: "New Source",
        icon: Upload,
      },
    ],
    [navigate]
  );

  const rowActions = useMemo(
    () => [
      {
        text: "Manage",
        icon: Cog,
        onClick: (rowData: any) => {
          setSelectedSourceGroupData(rowData);
          navigate(`/sources/step/${rowData?.sourceGroupId}`);
        },
      },
      {
        text: "Rename",
        icon: Pencil,
        onClick: (rowData: {
          sourceGroupId: React.SetStateAction<string | null>;
        }) => {
          setEditSourceId(rowData.sourceGroupId);
          setSourceOverlayIsOpen(true);
        },
      },
      {
        text: "Download Source Group",
        disabled: (rowData: { count: number }) => rowData.count === 0,
        icon: Download,
        onClick: async (rowData: {
          sourceGroupId: string;
          sourceName: any;
        }) => {
          const response = await downloadSourceGroup(rowData.sourceGroupId);
          const cleanCSV = validateAndCleanCSV(response.data.data);
          downloadCSV(cleanCSV, `${rowData.sourceName}.csv`);
        },
      },
      {
        text: "Generate Report",
        disabled: (rowData: { count: number }) => rowData.count === 0,
        icon: FileSliders,
        onClick: (rowData: { sourceGroupId: any }) => {
          navigate(`/reports/run/${rowData.sourceGroupId}`);
        },
      },
    ],
    [navigate, setSelectedSourceGroupData, validateAndCleanCSV]
  );

  const changePage = useCallback(
    (page: number) => {
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  const changePageSize = useCallback(
    (size: number) => {
      setPageSize(size);
    },
    [setPageSize]
  );

  return (
    <>
      <SourceModal
        isEditDialogOpen={sourceOverlayIsOpen}
        setIsEditDialogOpen={setSourceOverlayIsOpen}
        sourceId={editSourceId}
      />
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-1 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <Database
            size={18}
            strokeWidth={1.75}
            className="dark:text-gray-100"
          />
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Sources</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
      </section>

      <DashboardMainCard
        header={SOURCE_PAGE_HEADING}
        description={SOURCE_PAGE_DESCRIPTION}
      >
        <DataTable
          columns={cols}
          data={sourceGroups}
          loading={loading}
          globalFilter={{
            placeholder: "Filter Source Names...",
            accessorKey: "sourceName",
          }}
          actionButtons={actionButtons}
          pagination={{
            currentPage,
            pageSize,
            totalPages,
            totalRows,
            onPageChange: changePage,
            onPageSizeChange: changePageSize,
          }}
          rowActions={rowActions}
        />
      </DashboardMainCard>
    </>
  );
};

export default Sources;
