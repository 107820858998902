import { ApiWebSocket } from "@/types/apiwebsocket";
import { createContext, useContext } from "react";
import { Socket } from "socket.io-client";

// Define a Custom Socket Type for WebSocket events (Listen and Emit events)
export type CustomApiWebSocket = Socket<
  ApiWebSocket.ListenEvents,
  ApiWebSocket.EmitEvents
>;

// Define the state shape for the WebSocket context
export interface ApiWebSocketCtxState {
  socket: CustomApiWebSocket | null; // Allow socket to be null before it's initialized
  isSocketConnected: boolean;
}

// Create the WebSocket context, initially undefined
export const ApiWebSocketCtx = createContext<ApiWebSocketCtxState | undefined>(
  undefined
);

// Hook to access the WebSocket context
export const useApiWebSocketCtx = () => {
  const context = useContext(ApiWebSocketCtx);

  if (!context) {
    throw new Error(
      "useApiWebSocketCtx must be used within an ApiWebSocketCtxProvider"
    );
  }

  return context;
};
