import { Asset } from "@/types/report.type";
import React from "react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface AvatarWithLabelProps {
  asset?: Asset;
}

const SourceAsset: React.FC<AvatarWithLabelProps> = ({ asset }) => {
  if (!asset) return null;

  return (
    <div className="relative group w-12 h-12">
      <div className="absolute top-0 left-0 w-7 h-7">
        <Avatar className=" bg-gray-300 dark:bg-gray-600 p-1 relative flex h-full w-full shrink-0 overflow-hidden rounded-full">
          <AvatarImage src={asset.icon} alt={asset.label} />
        </Avatar>
      </div>
      <div className="absolute top-1/2 left-full -translate-y-1/2 translate-x-2 px-3 py-1 bg-gray-600 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out whitespace-nowrap">
        {asset.label}
      </div>
    </div>
  );
};

export default SourceAsset;