import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { Button } from "@/components/ui/button";
import { useApiWebSocketCtx } from "@/context/context";
import { useEffect, useState } from "react";

function RunSocket() {
  const [msg, setMsg] = useState("");
  const [messages, setMessages] = useState<string[]>([]);
  const [room, setRoom] = useState("");
  const { socket } = useApiWebSocketCtx();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    socket!.emit("message", msg, (code) => {
      console.log("Message response code:", code);
    });
  };

  useEffect(() => {
    socket!.emit("connection", "Connect please.... " ,(code) => {
      console.log("Message response code:", code);
    });
    socket!.on("message", (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      socket!.off("message");
    };
  }, [socket]);

  return (
    <DashboardMainCard header="Run Socket" description="Run a socket">
      <div>
        <h1>Messages</h1>
        {messages.map((msg, i) => (
          <p key={i} className="msg">
            {msg}
          </p>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="font-normal">
        <input
          className="dark:bg-gray-900"
          onChange={(e) => setRoom(e.target.value)}
          value={room}
          type="text"
          placeholder="Enter Room Key"
        />

        <input
          className="dark:bg-gray-900"
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
          type="text"
          placeholder="Enter message"
        />

        <Button type="submit" className="w-full my-4">
          Send Message
        </Button>
      </form>
    </DashboardMainCard>
  );
}

export default RunSocket;
