import {
  getAllWorkspaces,
  getWorkspaceDashboard,
} from "../services/workspaceService";

import { DashboardData } from "@/types/dashboard.type";
import { create } from "zustand";
import useReportsStore from "./reports.store";
import useSourcesStore from "./sources.store";

export interface Workspace {
  access: string;
  currency: string;
  orgId: string;
  userId: string;
  workspaceId: string;
  workspaceName: string;
  updated: number;
}

type WorkspaceState = {
  workspaces: Workspace[];
  selectedWorkspaceId: string;
  selectedWorkspaceDashboard: DashboardData | null;
  loading: boolean;
  setSelectedWorkspace: (workspaceId: string) => Promise<void>;
  fetchWorkspaces: () => Promise<void>;
  getSelectedWorkspace: () => Workspace | undefined;
  getSelectedWorkspaceDashboard: () => DashboardData | null | undefined;
};

const useWorkspaceStore = create<WorkspaceState>((set, get) => ({
  workspaces: [],
  selectedWorkspaceId: "",
  selectedWorkspaceDashboard: null,
  loading: false,

  setSelectedWorkspace: async (workspaceId: string) => {
    try {
      set({ loading: true, selectedWorkspaceId: workspaceId });

      const startDate = "2024-01";
      const endDate = "2024-01-31";

      const dashboardResponse = await getWorkspaceDashboard(
        workspaceId,
        startDate,
        endDate
      );

      if (dashboardResponse && dashboardResponse.data) {
        set({
          selectedWorkspaceDashboard: dashboardResponse.data.data,
        });

        useSourcesStore.getState().fetchAllSourceGroups();
        useReportsStore.getState().fetchAllReportGroups(100, 1);
      }
    } catch (error) {
      console.error("Failed to fetch workspace dashboard data:", error);
      set({ selectedWorkspaceDashboard: null });
    } finally {
      set({ loading: false });
    }
  },

  fetchWorkspaces: async () => {
    try {
      set({ loading: true });

      const response = await getAllWorkspaces();

      if (response && response.data && response.data.data) {
        set({
          workspaces: response.data.data,
        });

        if (get().selectedWorkspaceId === "" && get().workspaces.length > 0) {
          await get().setSelectedWorkspace(get().workspaces[0].workspaceId);
        }
      }
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
      set({ workspaces: [] });
    } finally {
      set({ loading: false });
    }
  },

  getSelectedWorkspace: () => {
    const selectedWorkspaceId = get().selectedWorkspaceId;
    if (!selectedWorkspaceId) {
      return undefined;
    }
    return get().workspaces.find(
      (workspace) => workspace.workspaceId === selectedWorkspaceId
    );
  },

  getSelectedWorkspaceDashboard: () => {
    return get().selectedWorkspaceDashboard;
  },
}));

export default useWorkspaceStore;
