import { Eye, Plus, Trash } from "lucide-react";

import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { ConfirmDialog } from "@/components/dialog/ConfirmDialog";
import DataTable from "@/components/table/CustomDataTable";
import { Progress } from "@/components/ui/progress";
import { useToast } from "@/components/ui/use-toast";
import { getNameBySymbol } from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReportGroup } from "@/types/report.type";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";

export const formatStatus = (status: string | boolean, message?: string) => {
  const statusMap: Record<
    string,
    { label: string; textColor: string; bgColor: string }
  > = {
    S: {
      label: "Success",
      textColor: "text-green-700",
      bgColor: "bg-green-200",
    },
    F: {
      label: "Failed",
      textColor: "text-red-700",
      bgColor: "bg-red-200",
    },
    P: {
      label: "Pending",
      textColor: "text-orange-700",
      bgColor: "bg-orange-200",
    },
    R: {
      label: "Running",
      textColor: "text-blue-700",
      bgColor: "bg-blue-200",
    },
  };

  let statusInfo;

  if (typeof status === 'boolean' || status === 'false') {
    statusInfo = status 
      ? { label: "Valid", textColor: "text-teal-700", bgColor: "bg-teal-200" }
      : { label: "Invalid", textColor: "text-red-700", bgColor: "bg-red-200" }; // Same as Failed
  } else {
    statusInfo = statusMap[status];
  }

  if (!statusInfo) return null;

  return (
    <div className="flex items-center">
      <span
        className={`${statusInfo.textColor} ${statusInfo.bgColor} px-2 py-1 rounded-full text-xs`}
      >
        {statusInfo.label}
      </span>
      {message && (
        <div className={`mx-4 py-1 px-1 ${statusInfo.textColor} text-xs`}>
          {message}
        </div>
      )}
    </div>
  );
};

function Reports() {
  const navigate = useNavigate();

  const { setSelectedReportGroupData } = useReportsStore();
  const loading = useReportsStore((state) => state.loading);
  const getReportGroups = useReportsStore((state) => state.reportGroups);
  const [deleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false);
  const [deleteReportGroupId, setDeleteReportGroupId] = useState("");
  const [reportGroupsData, setReportGroupsData] = useState<ReportGroup[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(10);

  const deleteReportGroup = useReportsStore((state) => state.deleteReportGroup);
  const fetchAllReportGroups = useReportsStore(
    (state) => state.fetchAllReportGroups
  );

  const { toast } = useToast();

  const handleFetchReportGroups = useCallback(async() => {
    const response = await fetchAllReportGroups(pageSize, currentPage);
      if (response && response.data && response.data.length > 0) {
        setReportGroupsData(response?.data);
        setTotalPages( response.pages || 1);
        setTotalRows(100);
        if (response.limit) setPageSize(response.limit);
      }
  }, [fetchAllReportGroups, pageSize, currentPage]);

  useEffect(() => {
    handleFetchReportGroups();
  }, [handleFetchReportGroups]);

  useEffect(() => {
    setReportGroupsData(getReportGroups);
  }, [setReportGroupsData, getReportGroups]);

  const cols = [
    {
      id: "reportName",
      name: "Name",
      width: 350,
    },
    {
      id: "groupType",
      name: "Type",
      width: 200,
      cell: (value: string) => getNameBySymbol(value),
    },
    {
      id: "progress",
      name: "Progress",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (value: any) => (
        <div className="w-1/2 text-center">
          <Progress value={value} />
          {value} %
        </div>
      ),
      width: 150,
    },
    {
      id: "status",
      name: "Status",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (value: any) => formatStatus(value),
      width: 200,
    },
  ];

  const handlePageChange = (page: number) => {
    const validPage = !isNaN(page) ? page : 2;
    setCurrentPage(validPage);
  };

  const handlePageSizeChange = (size: number) => {
    const validSize = !isNaN(size) && size > 0 ? size : 100;
    setPageSize(validSize);
  };
  return (
    <>
<section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-1 lg:py-2 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>
                  Reports
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
      </section>
    <DashboardMainCard header={"Reports"} description={"View and run reports"}>
      <ConfirmDialog
        title="Delete Report"
        description="Are you sure you want to delete this report?"
        open={deleteReportDialogOpen}
        setOpen={setDeleteReportDialogOpen}
        severity="error"
        onConfirm={async () => {
          await deleteReportGroup(deleteReportGroupId);
          toast({
            title: "Report deleted",
            description: "Report was deleted successfully",
          });
        }}
      />
      <DataTable
        columns={cols}
        data={reportGroupsData}
        loading={loading}
        pagination={{
          currentPage,
          pageSize,
          totalPages,
          totalRows,
          onPageChange: handlePageChange,
          onPageSizeChange: handlePageSizeChange,
        }}
        globalFilter={{
          placeholder: "Filter Report Names...",
          accessorKey: "reportName",
        }}
        actionButtons={[
          {
            onClick: () => navigate("/reports/run"),
            text: "New report",
            icon: Plus,
          },
        ]}
        rowActions={[
          {
            text: "View",
            onClick: (row) => {
              setSelectedReportGroupData(row);
              navigate(`/reports/${row.reportGroupId}`);
            },
            icon: Eye,
            disabled: (row) => row.status === "P",
          },
          {
            text: "Delete",
            onClick: (row) => {
              row.status !== "P" && setDeleteReportGroupId(row.reportGroupId);
              row.status !== "P" && setDeleteReportDialogOpen(true);
            },
            icon: Trash,
            disabled: (row) => row.status === "P",
            variant: "danger",
          },
        ]}
      />
    </DashboardMainCard>
    </>
  );
}

export default Reports;
