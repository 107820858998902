import React from 'react';
import { formatDistanceToNow, format } from 'date-fns';

interface RelativeDateTimeProps {
  value: number;
  className?: string;
}

export const RelativeDateTime: React.FC<RelativeDateTimeProps> = ({ value, className }) => {
  const date = new Date(value);
  const relativeTime = formatDistanceToNow(date, { addSuffix: true });
  const exactTime = format(date, "PPpp"); 

  return (
    <span title={exactTime} className={className}>
      {relativeTime}
    </span>
  );
};