import {
  getUser,
  loginUser,
  logoutUser,
  refreshUserToken,
  verifyUserToken,
} from "../services/userService";

import { User } from "../types/auth.type";
import { create } from "zustand";
import useWorkspaceStore from "./workspaces.store";

type AuthState = {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  setUser(user: User | null): void;
  refreshUser(): Promise<void>;
};

const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  loading: false,
  login: async (email: string, password: string) => {
    set({ loading: true });
    let user: User | null = null;
    try {
      const response = await loginUser({ email, password });
      user = { email, ...response.data };
    } catch (error: any) {
      throw error.response.data;
    }
    get().setUser(user);
  },
  logout: async () => {
    try {
      await logoutUser();
      get().setUser(null);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  },
  setUser: async (user: User) => {
    sessionStorage.setItem("user", JSON.stringify(user));
    set({ user, loading: false });
    if (user) {
      await useWorkspaceStore.getState().fetchWorkspaces();
    }
  },
  refreshUser: async () => {
    const userOnFile = get().user;
    const response = await getUser(userOnFile?.email || "");
    // map all keys of object to lowercase
    const remoteUser = Object.keys(response.data.rows[0]).reduce((acc, key) => {
      acc[key.toLowerCase()] = response.data.rows[0][key];
      return acc;
    }, {} as any);
    const user = { ...userOnFile, ...remoteUser };
    get().setUser(user);
  },
}));

const validateToken = async () => {
  useAuthStore.setState({ loading: true });
  const loggedInUser = JSON.parse(sessionStorage.getItem("user") || "null");
  if (!loggedInUser) {
    useAuthStore.setState({ loading: false });
    return;
  }
  try {
    const res: any = await verifyUserToken(loggedInUser?.email);
    const user = res.error ? null : loggedInUser;
    useAuthStore.getState().setUser(user);
  } catch (error: any) {
    if (
      error.response.data.error &&
      error.response.data.message === "Token expired."
    ) {
      await refreshUserToken(loggedInUser);
    }
    return;
  }
};
validateToken();

export default useAuthStore;