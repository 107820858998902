import { FC } from "react";
import { SourceEntriesForm } from "./SourceEntriesForm";
import { GeneralSheet } from "@/components/stepper/modals/GeneralSheet";
import useSourcesStore from "@/stores/sources.store";

interface SourceEntrySheetProps {
  sourceEntryId?: string | null;
  sourceType?: string | null;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (value: boolean) => void;
}

export const SourceEntrySheet: FC<SourceEntrySheetProps> = ({
  sourceEntryId,
  sourceType,
  isEditDialogOpen,
  setIsEditDialogOpen,
}) => {
  const id = sourceEntryId ?? null;
  const type = sourceType ?? "";
  const { selectedSourceGroup } = useSourcesStore();
  const addOrEditTitle = id ? "Edit" : "Add Entry";
  const customTitle = selectedSourceGroup?.sourceName ?? "source";

  return (
    <GeneralSheet
      title={`${addOrEditTitle}: ${customTitle}`}
      description={
        sourceEntryId
          ? "You can edit the entry details here"
          : "You can create a new entry here"
      }
      isEditPopoverOpen={isEditDialogOpen}
      setIsEditPopoverOpen={setIsEditDialogOpen}
    >
      <div className="w-full">
        <SourceEntriesForm
          entryId={id}
          sourceType={type}
          setIsDialogOpen={setIsEditDialogOpen}
        />
      </div>
    </GeneralSheet>
  );
};