import { Asset } from "@/types/report.type";
import React from "react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface AvatarWithLabelProps {
  asset?: Asset;
}

const TableAsset: React.FC<AvatarWithLabelProps> = ({ asset }) => {
  if (!asset) return null;

  return (
              <span className="flex align-middle text-center">
                <Avatar className="mr-2 h-5 w-5 bg-gray-200">
                  <AvatarImage
                    src={asset.icon}
                    alt={"t"}
                  />
                </Avatar>
                <p>{asset.label}</p>
              </span>
  );
};

export default TableAsset;