import React from 'react';
import { Pen } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { SourceEntry } from '@/types/sources.type';
import { lookupAsset } from '@/lib/utils';
import Asset from '@/components/misc/SourceAsset';
import KeyDisplay from './KeyDisplay';

interface SourceEntryCardProps {
  sourceEntry: SourceEntry;
  onEdit: () => void;
}

export const SourceEntryCard: React.FC<SourceEntryCardProps> = ({ sourceEntry, onEdit }) => {
  const asset = lookupAsset(sourceEntry && sourceEntry.network);

  return (
    <div className="flex items-center justify-between space-x-4 hover:bg-accent hover:text-accent-foreground rounded-md border p-2 dark:border-gray-700">
      <div className="flex items-center space-x-4">
        <span className="relative flex">
          <Asset asset={asset} />
        </span>
        <div>
          <p className="text-md font-medium leading-none">{sourceEntry.name}</p>
          <div className="flex items-center space-x-1">
            <KeyDisplay fullText={sourceEntry.public || ""} isError={false} />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <Button variant="ghost" onClick={onEdit}>
          <Pen className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};