import { ReactNode, useEffect, useRef, useState, useCallback } from "react";
import { User } from "@/types/auth.type";
import { io } from "socket.io-client";
import { ApiWebSocketCtx, CustomApiWebSocket } from "./context";
import { DownloadResponse } from "@/types/report.type";
import { useToast } from "@/components/ui/use-toast";

const ApiWebSocketCtxProvider = ({ children }: { children?: ReactNode }) => {
  const socketRef = useRef<CustomApiWebSocket | null>(null);
  const [socket, setSocket] = useState<CustomApiWebSocket | null>(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const { toast } = useToast();
  const user: User | null = JSON.parse(sessionStorage.getItem("user") || "null");
  const token = user?.token;

  const connectSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.connect();
    } else {
      const socketInstance: CustomApiWebSocket = io(import.meta.env.VITE_API_BASE_WS, {
        transports: ["websocket", "polling"],
        withCredentials: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        autoConnect: false,
        auth: { token },
      }) as CustomApiWebSocket;

      socketRef.current = socketInstance;
      setSocket(socketInstance);

      socketInstance.on("connect", () => {
        setIsSocketConnected(true);
        console.log("Connected to WebSocket with ID:", socketInstance.id);
        toast({
          title: "Socket Connected",
          description: "Real-time updates are now active.",
        });
      });

      socketInstance.on("disconnect", (reason) => {
        setIsSocketConnected(false);
        console.log(":: Disconnected from WebSocket:", reason);
        toast({
          title: "Socket Disconnected",
          description: "Attempting to reconnect...",
          variant: "destructive",
        });
        setTimeout(connectSocket, 5000); // Attempt to reconnect after 5 seconds
      });

      socketInstance.on("connect_error", (err: Error) => {
        console.error("WebSocket connection error: ", err.message);
        toast({
          title: "Connection Error",
          description: "Unable to connect to real-time updates. Retrying...",
          variant: "destructive",
        });
      });

      socketInstance.on("reportDownload", (data: DownloadResponse) => {
        console.log("Report downloaded:", data);
      });

      socketInstance.connect();
    }
  }, [token, toast]);

  useEffect(() => {
    connectSocket();

    return () => {
      console.log("Cleaning up WebSocket listeners...");
      if (socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.off("disconnect");
        socketRef.current.off("connect_error");
        socketRef.current.off("reportRunEntries");
        socketRef.current.off("reportDownload");
        socketRef.current.disconnect();
      }
    };
  }, [connectSocket]);

  useEffect(() => {
    if (!socketRef.current) return;

    const interval = setInterval(() => {
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit("heartbeat");
      }
    }, 30000); 

    return () => clearInterval(interval);
  }, []);

  return (
    <ApiWebSocketCtx.Provider value={{ socket, isSocketConnected }}>
      {children}
    </ApiWebSocketCtx.Provider>
  );
};

export default ApiWebSocketCtxProvider;