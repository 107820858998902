import { Steps } from "@/components/stepper/Steps";
import { MultiStepContextProvider } from "@/context/multistep";
import { ReactNode } from "react";
interface MultiStepFormLayout {
  children: ReactNode;
}

export default function MultiStepFormLayout({ children }: MultiStepFormLayout) {

  return (
    <MultiStepContextProvider>
      <div
        id="page-container"
        className="flex flex-col mx-auto w-full text-gray-900 dark:text-gray-100 dark:bg-gray-900 mb-2"
      >
        <main
          id="page-content"
          className="flex flex-auto flex-col max-w-6xl mx-auto w-full"
        >
          <Steps />

          {children}
        </main>
      </div>
    </MultiStepContextProvider>
  );
}