import { StepItem } from "./StepItem";
import useSourcesStore from "@/stores/sources.store";

const stepData = [
  { num: 1, title: "Create Source", description: "Create Source" },
  { num: 2, title: "Add Source Entries", description: "Add Source Entries" },
  { num: 3, title: "Review", description: "Review all source entries" },
  { num: 4, title: "Next Steps", description: "Either run a report or stop here." },
];

export function Steps() {
  const { step } = useSourcesStore();

  return (
    <div className="relative flex items-center justify-center lg:justify-between lg:pt-1 m-2">
      <ul className="relative flex w-full justify-between items-center">
        {stepData.map((info, index) => (
          <li
            key={info.num}
            className="relative flex flex-col items-center flex-1"
          >
            {index < stepData.length - 1 && (
              <span
                className={`absolute top-1/3 left-2/4 transform -translate-y-2/8 h-1 w-[calc(100%-1rem)] z-0 transition-all duration-500 ${
                  step > info.num ? 'bg-gray-900 dark:bg-gray-200' : 'bg-gray-400 dark:bg-gray-700'
                }`}
              ></span>
            )}

            {/* StepItem */}
            <StepItem infos={info} />
          </li>
        ))}
      </ul>
    </div>
  );
}