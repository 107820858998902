import { FC } from "react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Separator } from "@/components/ui/separator";

interface GeneralSheetProps {
  title: string;
  description: string;
  children: React.ReactNode;
  isEditPopoverOpen: boolean;
  setIsEditPopoverOpen: (value: boolean) => void;
}

export const GeneralSheet: FC<GeneralSheetProps> = ({
  title,
  description,
  children,
  isEditPopoverOpen,
  setIsEditPopoverOpen,
}) => {
  return (
    <Sheet open={isEditPopoverOpen} onOpenChange={setIsEditPopoverOpen}>
      <SheetContent side="right" className="w-full sm:w-[540px] md:w-[640px] lg:w-[720px]">
        <div className="flex flex-col h-full">
          <div className="p-4 space-y-2">
            <h4 className="font-medium leading-none">{title}</h4>
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>
          <Separator className="mb-2" />
          <div className="flex-grow overflow-y-auto py-2 pt-0">
            {children}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};