import React from 'react';
import { Pen, X, MoreVertical } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { lookupAsset } from '@/lib/utils';
import Asset from '@/components/misc/SourceAsset';
import { Badge } from "@/components/ui/badge"

import { Subaccount } from '@/types/sources.type';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import KeyDisplay from './KeyDisplay';

interface SubaccountEntry {
  key: number;
  entry: Subaccount;
  onEdit: () => void;
  onDelete: () => void;
}

export const SubaccountEntry: React.FC<SubaccountEntry> = ({key, entry, onEdit, onDelete }) => {
  const asset = lookupAsset(entry && entry.network);

  return (
    <div key={key} className="flex items-center justify-between space-x-4 hover:bg-accent hover:text-accent-foreground rounded-md border p-2 dark:border-gray-700 my-2">
      <div className="flex items-center space-x-4">
        <span className="relative flex border border-gray-200 dark:border-gray-800 rounded-full">
          <Asset asset={asset} />
        </span>
        <div>
          <p className="text-md font-medium leading-none">{entry.name ? entry.name : `subaccount-${key}`}</p>
            <KeyDisplay fullText={entry.public || ""} isError={false} />
        </div>
      </div>

      <div className="flex items-center space-x-0">
      <Badge variant="outline">subaccount</Badge>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={onEdit}>
            <Pen className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={onDelete}>
            <X className="mr-2 h-4 w-4 text-destructive" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      </div>
    </div>
  );
};