import {
  safelyAccessFirstAssetArray,
  safelyAccessFlatArray,
} from "@/lib/utils";
import {
  ApiTransactionsPayload,
  AssetTransaction,
  ChartDataItem,
  DownloadResponse,
  ExTransactionData,
  Report,
  ReportGroup,
  TransactionData,
  TxData,
} from "@/types/report.type";
import { create } from "zustand";
import {
  deleteReportGroup,
  downloadReportGroup,
  downloadTx,
  getAllReportGroups,
  getGraphData,
  getReportsPage,
  getTransactions,
} from "../services/reportService";
import useWorkspaceStore from "./workspaces.store";

type ReportsState = {
  loading: boolean;
  reportGroups: ReportGroup[];
  reports: Report[];
  report: Report | null;
  selectedReportGroup: string;
  totalPages: number;
  totalRows: number;
  pageSize: number;
  currentPage: number;
  selectedReportGroupData: ReportGroup | null;
  fetchAllReportGroups: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<ReportGroup>>;
  fetchAllReportEntriesForSelectedGroup: (
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Report>>;
  fetchReportEntriesForSelectedGroup: (
    reportGroupId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<Report>>;
  setSelectedReportGroup: (reportGroupId: string) => void;
  setSelectedReportGroupData: (reportGroup: ReportGroup) => void;
  setSelectedReportData: (report: Report) => void;
  deleteReportGroup: (reportGroupId: string) => Promise<void>;
  getTransactions: (
    reportId: string,
    limit: number,
    page: number
  ) => Promise<(TransactionData | ExTransactionData)[]>;
  getGraphData: (reportId: string) => Promise<ChartDataItem[]>;
  downloadTx: (reportId: string) => Promise<DownloadResponse>;
  downloadReport: () => Promise<string>;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (size: number) => void;
};

const useReportsStore = create<ReportsState>((set, get) => ({
  loading: false,
  reportGroups: [],
  reports: [],
  report: null,
  totalPages: 1,
  totalRows: 1,
  pageSize: 100,
  currentPage: 1,
  selectedReportGroup: "",
  selectedReportGroupData: null,

  fetchAllReportGroups: async (limit: number = 10, page: number = 1) => {
    set({ loading: true });
    const workspace = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";

    if (!workspaceId) {
      set({ loading: false });
      return Promise.reject(new Error("Workspace ID is missing"));
    }

    try {
      const response = await getAllReportGroups(workspaceId, limit, page);
      const sortedData = response && response.data && response.data.data.data.sort((a: ReportGroup, b: ReportGroup) => b.updated - a.updated);
      set({
        reportGroups: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.data.pages || 0,
        max: response.data.data.max || 0,
        data: sortedData,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching report groups:", error);
      return Promise.reject(error);
    }
  },

  fetchReportEntriesForSelectedGroup: async (
    reportGroupId: string,
    limit: number = 10,
    page: number = 1
  ) => {
    set({ loading: true });

    if (!reportGroupId) {
      set({ loading: false });
      return Promise.reject(new Error("Report Group ID is missing"));
    }

    try {
      const response = await getReportsPage(reportGroupId, limit, page);
      set({
        reports: response.data.data.data,
        loading: false,
      });

      return {
        page,
        limit,
        pages: response.data.data.pages || 0,
        max: response.data.data.max || 0,
        data: response.data.data.data,
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching entries:", error);
      return Promise.reject(error);
    }
  },

  fetchAllReportEntriesForSelectedGroup: async (
    limit: number = 100,
    page: number = 1
  ) => {
    set({ loading: true });
    const selectedReportGroup = get().selectedReportGroup;

    if (!selectedReportGroup) {
      set({ loading: false });
      return Promise.reject(new Error("Selected Report Group is missing"));
    }

    try {
      const response = await getReportsPage(selectedReportGroup, limit, page);
      const sortedData = response && response.data.data && response.data.data.data.sort((a: any, b: any) => b.updated - a.updated);
      set({
        reports: sortedData,
        loading: false,
      });


      return {
        page,
        limit,
        pages: response.data.data[0]?.pages || 0,
        max: response.data.data[0]?.max || 0,
        data: response.data.data[0],
      };
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching report entries:", error);
      return Promise.reject(error);
    }
  },

  setSelectedReportGroup: (reportGroupId: string) => {
    set({
      selectedReportGroup: reportGroupId,
    });
    get().fetchAllReportEntriesForSelectedGroup(10, 1);
  },

  setSelectedReportGroupData: (reportGroup: ReportGroup) => {
    set({
      selectedReportGroupData: reportGroup,
    });
  },
  setSelectedReportData: (report: Report) => {
    set({
      report: report,
    });
  },

  deleteReportGroup: async (reportGroupId: string) => {
    set({ loading: true });
    try {
      await deleteReportGroup(reportGroupId);
      set({ loading: false });
      get().fetchAllReportGroups(10, 1);
    } catch (error) {
      set({ loading: false });
      console.error("Error deleting report group:", error);
    }
  },
  getTransactions: async (
    reportId: string,
    limit: number,
    page: number
  ): Promise<(TransactionData | ExTransactionData)[] > => {
    set({ loading: true });
  
    try {
      const response = await getTransactions(reportId, limit, page);
      const selectedReportGroup = get().selectedReportGroupData;
      let flatTransactions: (TransactionData | ExTransactionData)[] = [];
      if (selectedReportGroup && selectedReportGroup.groupType === "EXCH") {
        flatTransactions = safelyAccessFlatArray<TxData>(response.data).flatMap((tx) => tx.data);
        
        flatTransactions = flatTransactions.map((tx) => ({
          ...tx,
        })) as ExTransactionData[];
  
        flatTransactions.sort((a, b) => (a as ExTransactionData).datetime - (b as ExTransactionData).datetime);
      } else {
        flatTransactions = safelyAccessFlatArray<TransactionData>(response.data);
        flatTransactions.sort((a, b) => {
          if ('blockheight' in a && 'blockheight' in b) {
            return b.blockheight - a.blockheight;
          }
          return 0; 
        });
      }
  
      set({ loading: false });

    return flatTransactions;
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching transactions:", error);
      throw error;
    }
  },
  
  getGraphData: async (reportId: string) => {
    set({ loading: true });

    try {
      const response = await getGraphData(reportId);

      // @ts-expect-error: Argument of type 'AxiosResponse<any, any>' is not assignable to parameter of type 'AssetApiResponse<AssetTransaction>'.
      const assetTransactions = safelyAccessFirstAssetArray<AssetTransaction>(response);

      set({ loading: false });
      return assetTransactions;
    } catch (error) {
      set({ loading: false });
      console.error("Error fetching graph data:", error);
      throw error;
    }
  },

  downloadTx: async (reportId: string) => {
    try {
      const response = await downloadTx(reportId);
      return response.data;
    } catch (error) {
      console.error("Error downloading transaction report:", error);
      throw error;
    }
  },

  downloadReport: async () => {
    const reportGroupId = get().selectedReportGroup;

    try {
      const response = await downloadReportGroup(reportGroupId);
      return response.data.downloadLink;
    } catch (error) {
      console.error("Error downloading report group:", error);
      throw error;
    }
  },
  handlePageChange: (page: number) => {
    set({ currentPage: page });
    if (get().selectedReportGroup) {
      get().fetchAllReportEntriesForSelectedGroup(
        get().pageSize,
        get().currentPage+1
      );
    }
  },
  handlePageSizeChange: (size: number) => {
    set({ pageSize: size, currentPage: 1 });
    if (get().selectedReportGroup) {
      get().fetchAllReportEntriesForSelectedGroup(
        get().pageSize,
        get().currentPage,
      );
    }
  },
}));

export default useReportsStore;
