/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import { useCallback, useState } from "react";
import { Plus, Upload, Pencil, Trash, PlusCircle, File } from "lucide-react";
import DataTable from "@/components/table/CustomDataTable";
import { SourceEntrySheet } from "@/components/stepper/modals/SourceEntrySheet";
import useSourcesStore, { useNetworks, usePagination, useSources, useSourcesLoading } from "@/stores/sources.store";
import { NetworksData, Source } from "@/types/sources.type";
import { SourceUploadModal } from "@/components/stepper/modals/SourceEntriesUploadModal";
import { ConfirmDialog } from "@/components/dialog/ConfirmDialog";
import { generateCellIcons } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { colsMap, filtersMap } from "@/components/misc/TableConfig";
import { toast } from "@/components/ui/use-toast";

export function StepSourceEntries() {
   const { 
    deleteSourceEntry,
    setSelectedSource,
    setSourcesCurrentPage,
    setPageSize,
  } = useSourcesStore();

  const { sourcesCurrentPage, pageSize, totalPages, totalRows, } = usePagination();
  const loading = useSourcesLoading();
  const networks = useNetworks();
  const sources = useSources();

  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);
  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [deleteEntryDialogOpen, setDeleteEntryDialogOpen] = useState(false);

  const [selectedSourceGroup] = useSourcesStore((state) => [
    state.selectedSourceGroup,
  ]);


  const [editSourceEntryId, setEditSourceEntryId] = useState<string | null>(
    null
  );
  const [deleteSourceEntryId, setDeleteSourceEntryId] = useState<string | null>(
    null
  );

  const { selectedSourceGroupId} = useSourcesStore();
  const sourceGroupId = selectedSourceGroupId || "";

  const deleteEntry = async (entryId: string) => {
    try {
      await deleteSourceEntry(entryId);
      toast({
        title: "Entry deleted",
        description: "Source entry was deleted successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete source entry",
        variant: "destructive",
      });
    }
  };


  const selectedGroupType =
    selectedSourceGroup?.groupType as keyof NetworksData;

  const cellIcons = generateCellIcons(networks, selectedGroupType);

  const cols = selectedSourceGroup
    ? colsMap[selectedSourceGroup.groupType]
    : [];
  const filter = selectedSourceGroup
    ? filtersMap[selectedSourceGroup.groupType]
    : undefined;

  const handleImport = (event: any) => {
    event?.preventDefault();
    setIsUploadOverlayOpen(true);
  };

  const handleAddEntry = (event: any) => {

    event?.preventDefault();
    setEditSourceEntryId(null);
    setIsEditOverlayOpen(true);
  };

  const handleEditEntry = (rowData: Source) => {
    setSelectedSource(rowData);
    setEditSourceEntryId(rowData.sourceId);
    setIsEditOverlayOpen(true);
  };

  const handleDeleteEntryClick = (rowData: Source) => {
    setDeleteSourceEntryId(rowData.sourceId);
    setDeleteEntryDialogOpen(true);
  };

  const handleDeleteEntryConfirm = () => {
    if (deleteSourceEntryId) {
      deleteEntry(deleteSourceEntryId);
      setDeleteEntryDialogOpen(false);
      setDeleteSourceEntryId(null);
    }
  };

  const changePage = useCallback((page: number) => {
    setSourcesCurrentPage(page);
  }, [setSourcesCurrentPage]);
  
  const changePageSize = useCallback((size: number) => {
    setPageSize(size);
  }, [setPageSize]);


  return (
    <div>
      <ConfirmDialog
        title="Delete Entry"
        description="Are you sure you want to delete this entry?"
        open={deleteEntryDialogOpen}
        setOpen={setDeleteEntryDialogOpen}
        severity="error"
        onConfirm={handleDeleteEntryConfirm}
      />
      <SourceUploadModal
        sourceGroupId={sourceGroupId}
        isOpen={isUploadOverlayOpen}
        setIsOpen={setIsUploadOverlayOpen}
      />
      <SourceEntrySheet
        isEditDialogOpen={isEditOverlayOpen}
        setIsEditDialogOpen={setIsEditOverlayOpen}
        sourceEntryId={editSourceEntryId}
        sourceType={selectedSourceGroup?.groupType}
      />
      {sources && sources.length > 0 ? (
        <>
          <DataTable
            columns={cols}
            columnIcons={[
              { field: "entry.network", icons: cellIcons },
              { field: "entry.asset", icons: cellIcons },
            ]}
            data={sources}
            loading={loading}
            globalFilter={filter}
            actionButtons={[
              {
                onClick: () => {
                  const handleImport = (event: any) => {
                    event?.preventDefault();
                    setIsUploadOverlayOpen(true);
                  };
                  handleImport(event);
                },
                text: "Import CSV",
                icon: Upload,
              },
              {
                onClick: () => {
                  const handleEdit = (event: any) => {
                    event?.preventDefault();
                    setIsEditOverlayOpen(true);
                    setEditSourceEntryId(null);
                  };
                  handleEdit(event);
                },
                text: "Add Entry",
                icon: Plus,
              },
            ]}
            pagination={{
              currentPage: sourcesCurrentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: changePage,
              onPageSizeChange: changePageSize,
            }}
            rowActions={[
              {
                onClick: handleEditEntry,
                text: "Edit Entry",
                icon: Pencil,
              },
              {
                onClick: handleDeleteEntryClick,
                text: "Delete Entry",
                icon: Trash,
                variant: "danger",
              },
            ]}
          />
        </>
      ) : (
        <>
          <div className="flex shrink-0 items-center justify-center rounded-md border border-dashed dark:border-gray-500">
            <div className="mx-auto flex min-h-[500px] max-w-[420px] flex-col items-center justify-center text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="h-20 w-20 text-muted-foreground"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="11" r="1"></circle>
                <path d="M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5ZM8 14a5 5 0 1 1 8 0"></path>
                <path d="M17 18.5a9 9 0 1 0-10 0"></path>
              </svg>
              <h3 className="mt-4 text-lg font-semibold">No Entries Added</h3>
              <p className="mb-4 mt-2 text-sm text-muted-foreground">
                You have not added any source entries. Add one below.
              </p>
              <div className="p-6 pt-0 flex items-center justify-center gap-4">
                <Button size="sm" className="h-7 gap-1" onClick={handleImport}>
                  <File className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Bulk Upload CSV
                  </span>
                </Button>
                <Button
                  size="sm"
                  className="h-7 gap-1"
                  onClick={handleAddEntry}
                >
                  <PlusCircle className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    Add Source Entry
                  </span>
                </Button>

              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
