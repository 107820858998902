import { Asset } from "@/types/report.type";
import React from "react";
import { Avatar, AvatarImage } from "../ui/avatar";

interface AvatarWithLabelProps {
  asset?: Asset;
  network?: Asset;
}

const NetworkAsset: React.FC<AvatarWithLabelProps> = ({ asset, network }) => {
  let isSame = false;
  if (asset && network) {
    isSame = asset.label === network.label && asset.name === network.name;
  }

  return (
    <div className={`relative group w-6 h-8 ${!isSame ? "mr-3" : ""}`}>
      {network && asset && (
        <>
          {
            <>
              <div className="absolute top-0 left-0">
                <Avatar className="w-9 h-9 rounded-full bg-gray-300 border-2 border-gray-200 dark:border-gray-100">
                  <AvatarImage src={asset.icon} alt={asset.label} />
                </Avatar>
              </div>

              <div className="absolute left-6 mr-2">
                <Avatar className="w-3 h-3 rounded-full bg-gray-300 border">
                  <AvatarImage src={network.icon} alt={network.label} />
                </Avatar>
              </div>
            </>
          }

<div className="absolute top-1/2 left-full -translate-y-1/2 translate-x-2 px-3 py-1 bg-gray-600 text-white text-sm rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out whitespace-nowrap">
{asset.label === network.label ? asset.label : `${asset.label} on ${network.label}`}
          </div>
        </>
      )}
    </div>
  );
};

export default NetworkAsset;
