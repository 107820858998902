import MultiStepFormLayout from "@/components/layout/MultiStepLayout";
import { MultiForm } from "@/components/stepper/MultiForm";
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import { Database } from "lucide-react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function MultiStepForm() {

  const { sourceGroupId } = useParams<{ sourceGroupId: string | undefined }>();
  const initialStep = sourceGroupId ? 2 : 1;
  
  return (
    <>
<section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-1 lg:py-2 lg:pb-1 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
              <Database
                size={18}
                strokeWidth={1.75}
                className=" dark:text-gray-100"
              />
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link to="/sources">Sources</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {initialStep === 2? "Edit Source Entries" :  "Create New Source"}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
      </section>
    <MultiStepFormLayout>

        <MultiForm initialStep={initialStep}/>
    </MultiStepFormLayout>
    </>
  );
}
