/* eslint-disable @typescript-eslint/no-explicit-any */
import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { AlertDestructive } from "@/components/alerts/AlertDestructive";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { AUTH_LOGIN_BUTTON } from "@/config/auth";
import { LOADING_TEXT } from "@/config/general";
import useAuthStore from "@/stores/auth.store";
import { LoginSchema } from "@/zod/auth.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { HashLink as Link } from "react-router-hash-link";

export const LoginForm = () => {
  const [login] = useAuthStore((state) => [state.login]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  type LoginFormType = z.infer<typeof LoginSchema>;

  const loginForm = useForm<LoginFormType>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LoginFormType) => {
    try {
      setLoading(true);
      await login(data.email, data.password);
    } catch (error: any) {
      setError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...loginForm}>
      <div className="mt-4">
        {error ? <AlertDestructive title="Error" description={error} /> : null}
      </div>
      <form onSubmit={loginForm.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={loginForm.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-gray-900 dark:text-gray-100">
                Email
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  className="text-gray-900 dark:text-gray-100 dark:bg-gray-900 dark:border-gray-700"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={loginForm.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-gray-900 dark:text-gray-100">
                Password
              </FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="Enter your password"
                  {...field}
                  className="text-gray-900 dark:text-gray-100 dark:bg-gray-900 dark:border-gray-700"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled className="w-full" size="lg">
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" className="w-full" size="lg">
            {AUTH_LOGIN_BUTTON}
          </Button>
        )}

        <div className="text-center mt-4">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Don't have an account yet?{" "}
            <Link
              to="/home#contact"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-500"
            >
              Register a new account
            </Link>
            .
          </p>
        </div>
      </form>
    </Form>
  );
};
