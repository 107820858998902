import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Asset from "@/components/misc/Asset";
import { lookupAsset } from "@/lib/utils";
import AddressCell from "../misc/AddressCell";
import { formatDistanceToNow } from "date-fns";

interface Transaction {
  reportId: string;
  asset: string;
  after: string;
  price: string;
  blocktime: number;
}

interface TransactionsTableProps {
  transactions: Transaction[];
}

function TransactionsTable({ transactions }: TransactionsTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Asset</TableHead>
          <TableHead className="hidden xl:table-cell">Updated</TableHead>
          <TableHead className="text-right">Value</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((tx, index) => (
          <TableRow key={index}>
            <TableCell>
              <div className="flex items-center space-x-1 -space-y-3">
                <div className="flex-shrink-0 pt-1">
                  <Asset asset={lookupAsset(tx.asset)} />
                </div>
                <div className="flex flex-col justify-center">
                  <span className="font-semibold text-sm">{tx.asset}</span>
                  <div className="text-xs text-muted-foreground">
                    <AddressCell fullAddress={tx.reportId} />
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell className="hidden xl:table-cell text-sm">
              {formatDistanceToNow(new Date(tx.blocktime), { addSuffix: true })}
            </TableCell>
            <TableCell className="text-right font-medium">
              {parseFloat(tx.after).toFixed(2)} {tx.asset}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TransactionsTable;