import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { downloadSourceGroup } from "@/services/sourceService";
import useSourcesStore from "@/stores/sources.store";
import { validateAndCleanFile } from "@/zod/source.zod";
import { format, subHours } from "date-fns";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import { RelativeDateTime } from "../misc/RelativeDateTime";
import { DatePicker } from "@/components/ui/date-picker";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "@/components/ui/card";
import { Label } from "@/components/ui/label";

function StepFour() {
  const { selectedSourceGroup } = useSourcesStore();
  const [runDate, setRunDate] = useState<Date>(subHours(new Date(), 1));
  const defaultRunDate = subHours(new Date(), 1);

  const downloadSource = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!selectedSourceGroup) return;
    const response = await downloadSourceGroup(selectedSourceGroup.sourceGroupId);
    const cleanCSV = validateAndCleanFile(
      response.data.data,
      selectedSourceGroup?.groupType || ""
    );
    downloadCSV(cleanCSV, `${selectedSourceGroup?.sourceName}.csv`);
  };

  if (!selectedSourceGroup) {
    return <div className="text-center text-gray-500 dark:text-gray-400">No source group selected</div>;
  }

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Report Summary</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label className="font-medium">Source Name</Label>
            <p className="text-sm">{selectedSourceGroup.sourceName}</p>
          </div>
          <div>
            <Label className="font-medium">Source ID</Label>
            <p className="text-sm">{selectedSourceGroup.sourceGroupId}</p>
          </div>
          <div>
            <Label className="font-medium">Group Type</Label>

        <span className="ml-0 border px-2 rounded-full text-sm text-gray-900 dark:text-gray-300">
          {getNameBySymbol(selectedSourceGroup?.groupType || "")}
        </span>
          </div>
          <div>
            <Label className="font-medium">Last Updated </Label>
            <RelativeDateTime value={selectedSourceGroup?.updated || Number(defaultRunDate)} />
          </div>
        </div>
        <div>
          <Label className="font-medium">Default Run Date</Label>
          <p className="text-sm">{format(defaultRunDate, 'yyyy-MM-dd HH:mm:ss')} (1 hour ago)</p>
        </div>
        <div>
          <Label className="font-medium">Run Date</Label>
          <DatePicker
            date={runDate}
            setDate={setRunDate}
          />
        </div>
      </CardContent>
      <CardFooter>
        <Button
          onClick={downloadSource}
          className="w-full"
          variant="outline"
        >
          <Download className="h-4 w-4 mr-2" />
          Download Source Entries
        </Button>
      </CardFooter>
    </Card>
  );
}

export default StepFour;