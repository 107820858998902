import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Check, Clipboard, XCircle, KeyRound } from "lucide-react"; 
import * as React from "react";

interface CellProps {
  fullText: string;
  isError: boolean; 
}

const KeyDisplay: React.FC<CellProps> = ({ fullText, isError }) => {
  const truncatedText = fullText.length > 10 ? `${fullText.slice(0, 10)}...` : fullText;
  const [isOpen, setIsOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div
          className="flex items-center space-x-1 cursor-pointer text-muted-foreground text-sm"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {isError ? (
            <XCircle className="h-3 w-3 text-red-500" />
          ) : (
            <KeyRound className="h-3 w-3 text-muted-foreground" />
          )}

          <span>{truncatedText}</span>
          {copied ? (
            <Check className="h-4 w-4 text-green-500" />
          ) : (
            <Clipboard
              className="h-4 w-4 text-gray-700 dark:text-gray-400"
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(fullText);
              }}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-full max-w-2xl p-4">
        <div className="flex flex-col space-y-2">
          <span className="break-words text-sm text-muted-foreground">
            {fullText}
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default KeyDisplay;
