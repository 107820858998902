/* eslint-disable @typescript-eslint/no-explicit-any */
import { ASSETS, ENTRY_TYPES } from "@/config/general";
import { Asset, AssetApiResponse } from "@/types/report.type";
import {
  BalaItem,
  DefiItem,
  IconItem,
  NetworksData,
} from "@/types/sources.type";
import { IconsList } from "@/types/tables.type";
import { type ClassValue, clsx } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function downloadCSV(csvContent: string, fileName: string) {
  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a link element, use it to download the Blob, and then remove it
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function formatNumber(value: string) {
  let formatter;
  const number = parseFloat(value);
  if (number > 1 || number < -1) {
    formatter = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  } else {
    formatter = new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 0,
    });
  }
  return formatter.format(number);
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export function formatArrayDate(timestamps: number[]): string | null {
  if (timestamps && timestamps.length > 0 && timestamps[0] != null) {
    return new Date(timestamps[0]).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return null;
}

export function formatArrayLongDate(timestamps: number[]): string | null {
  if (timestamps && timestamps.length > 0 && timestamps[0] != null) {
    return new Date(timestamps[0])
      .toLocaleString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZoneName: "short",
      })
      .replace(/\s(am|pm)/i, (match) => match.toUpperCase());
  } else if (timestamps && timestamps.length > 0) {
    return timestamps
      .map((timestamp) =>
        new Date(timestamp)
          .toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
            timeZoneName: "short",
          })
          .replace(/\s(am|pm)/i, (match) => match.toUpperCase())
      )
      .join(", ");
  }
  return null;
}

export function safelyAccessFlatArray<T>(response: any): T[] {
  let currentLevel: any = response; // Start from the top level of the response
  while (
    currentLevel &&
    currentLevel.data &&
    Array.isArray(currentLevel.data) &&
    currentLevel.data.length > 0
  ) {

    currentLevel = currentLevel.data[0]; 

  }

  while (currentLevel && Array.isArray(currentLevel.data) && currentLevel.data.length > 0) {
    currentLevel = currentLevel.data[0]; 
  }

  if (currentLevel && Array.isArray(currentLevel)) {
    return currentLevel as T[]; 
  }
  return [];
}




export function safelyAccessFirstAssetArray<T>(
  response: AssetApiResponse<T>
): T[] {
  //@ts-ignore-error
  if (response && !response.error && Array.isArray(response.data.data)) {
    //@ts-ignore-error
    const firstDataLayer = response.data.data[0];
    if (
      firstDataLayer &&
      Array.isArray(firstDataLayer.data) &&
      firstDataLayer.data.length > 0
    ) {
      const firstInnerLayer = firstDataLayer.data[0];
      const firstKey = Object.keys(firstInnerLayer)[0];
      if (firstKey) {
        return firstInnerLayer[firstKey];
      }
    }
  }
  return [];
}

export const getNameBySymbol = (symbol: string): string | undefined => {
  const trimmedSymbol = symbol.trim().toUpperCase();
  const entry = ENTRY_TYPES.find(
    (entry) => entry.symbol.toUpperCase() === trimmedSymbol
  );
  return entry ? entry.name : undefined;
};

export function formatBalance(value: number): string {
  if (Math.abs(value) < 0.0001 && value !== 0) {
    return "> 0.0000"; // Handle values less than 0.0001
  } else if (Math.abs(value) >= 1e12) {
    return (value / 1e12).toFixed(2) + "T"; // Handle trillions
  } else if (Math.abs(value) >= 1e9) {
    return (value / 1e9).toFixed(2) + "B"; // Handle billions
  } else if (Math.abs(value) >= 1e6) {
    return (value / 1e6).toFixed(2) + "M"; // Handle millions
  } else if (Math.abs(value) >= 1e3) {
    return (value / 1e3).toFixed(2) + "K"; // Handle thousands
  } else {
    return value?.toFixed(4); // Handle values below 1000, up to 4 decimal places
  }
}

export const lookupAsset = (identifier: string): Asset | undefined => {
  if (!identifier || typeof identifier !== 'string') {
    return undefined;
  }

  const normalizedIdentifier = identifier.trim().toLowerCase();

  if (normalizedIdentifier.length === 0) {
    return undefined;
  }

  return ASSETS.find(
    (asset) =>
      asset.label.toLowerCase() === normalizedIdentifier ||
      asset.name.toLowerCase() === normalizedIdentifier
  );
};

export const generateCellIcons = (
  networks: NetworksData,
  selectedGroupType: keyof NetworksData = "BALA"
): IconsList[] => {
  const cellIcons: IconsList[] = [];

  const selectedData = networks[selectedGroupType];

  if (selectedGroupType === "BALA") {
    (selectedData as BalaItem[]).forEach((item) => {
      cellIcons.push({
        value: item.native.symbol,
        img: item.native.icon,
      });

      item.tokens.forEach((token) => {
        cellIcons.push({
          value: token.symbol,
          img: token.icon,
        });
      });

      item.nfts.forEach((nft) => {
        cellIcons.push({
          value: nft.symbol,
          img: nft.icon || "",
        });
      });
    });
  } else if (selectedGroupType === "DEFI") {
    (selectedData as DefiItem[]).forEach((item) => {
      cellIcons.push({
        value: item.symbol,
        img: item.icon,
      });

      item.protocols.forEach((protocol) => {
        cellIcons.push({
          value: protocol.symbol,
          img: protocol.icon,
        });
      });
    });
  }
  else {
    (selectedData as IconItem[]).forEach((item) => {
      cellIcons.push({
        value: item.symbol,
        img: item.icon,
      });
    });
  }

  return cellIcons;
};


export const capitalizeFirstChar = (text: string) => {
  if (!text) return text; 
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const extractFields = (data: any, fields: string[]) => {
  return fields.reduce((acc: any, field: string) => {
    acc[field] = data[field];
    return acc;
  }, {});
};