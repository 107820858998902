/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import { useCallback, useEffect, useState } from "react";
import DataTable from "@/components/table/CustomDataTable";
import useSourcesStore, { useNetworks, usePagination, useSources } from "@/stores/sources.store";
import { NetworksData, Source } from "@/types/sources.type";
import { generateCellIcons } from "@/lib/utils";
import { colsMap, filtersMap } from "@/components/misc/TableConfig";

export function StepSummary() {
   const { 
    loading, 
    setCurrentPage,
    setPageSize,
  } = useSourcesStore();

  const { currentPage, pageSize, totalPages, totalRows, } = usePagination();
  const networks = useNetworks();
  const sources = useSources();


  const [selectedSourceGroup, getSourceEntriesForSourceGroup] = useSourcesStore((state) => [
    state.selectedSourceGroup,
    state.fetchSourceEntriesForSelectedGroup,
  ]);

  const [sourceEntries, setSourceEntries] = useState<Source[]>([]);


  const { selectedSourceGroupId} = useSourcesStore();

  const fetchSourceEntriesForGroup = useCallback(
    async (sourceGroupId: string) => {
      const response = await getSourceEntriesForSourceGroup(
        sourceGroupId,
        pageSize,
        currentPage
      );
      if (response && response.data && response.data.length > 0) {
        setSourceEntries(response.data);
      }
    },
    [currentPage, getSourceEntriesForSourceGroup, pageSize]
  );

  useEffect(() => {
    if (selectedSourceGroupId) {
      fetchSourceEntriesForGroup(selectedSourceGroupId);
    }
  }, [selectedSourceGroupId, fetchSourceEntriesForGroup]);

  const selectedGroupType =
    selectedSourceGroup?.groupType as keyof NetworksData;

  const cellIcons = generateCellIcons(networks, selectedGroupType);

  const cols = selectedSourceGroup
    ? colsMap[selectedSourceGroup.groupType]
    : [];
  const filter = selectedSourceGroup
    ? filtersMap[selectedSourceGroup.groupType]
    : undefined;

  const changePage = useCallback((page: number) => {
    setCurrentPage(page);
  }, [setCurrentPage]);
  
  const changePageSize = useCallback((size: number) => {
    setPageSize(size);
  }, [setPageSize]);


  return (
    <div>
     {sourceEntries && sourceEntries.length > 0 ? (
        <>
          <DataTable
            columns={cols}
            columnIcons={[
              { field: "entry.network", icons: cellIcons },
              { field: "entry.asset", icons: cellIcons },
            ]}
            data={sources}
            loading={loading}
            globalFilter={filter}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              totalRows,
              onPageChange: changePage,
              onPageSizeChange: changePageSize,
            }}
          />
        </>
      ) : (
        <>
          <div className="flex shrink-0 items-center justify-center rounded-md border border-dashed dark:border-gray-500">
            <div className="mx-auto flex min-h-[500px] max-w-[420px] flex-col items-center justify-center text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="h-20 w-20 text-muted-foreground"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="11" r="1"></circle>
                <path d="M11 17a1 1 0 0 1 2 0c0 .5-.34 3-.5 4.5a.5.5 0 0 1-1 0c-.16-1.5-.5-4-.5-4.5ZM8 14a5 5 0 1 1 8 0"></path>
                <path d="M17 18.5a9 9 0 1 0-10 0"></path>
              </svg>
              <h3 className="mt-4 text-lg font-semibold">No Entries Added</h3>
              <p className="mb-4 mt-2 text-sm text-muted-foreground">
                You have not added any source entries yet.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
