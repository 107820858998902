import { FC } from "react";
import { GeneralModal } from "@/components/dialog/GeneralModal";
import { SourceUploadForm } from "./SourceUploadForm";

interface SourceUploadModalProps {
  sourceGroupId: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSuccess?: () => void;
}

export const SourceUploadModal: FC<SourceUploadModalProps> = ({
  sourceGroupId,
  isOpen,
  setIsOpen,
  onSuccess,
}) => {
  return (
    <GeneralModal
      title={"Upload Source"}
      description={
        "Upload a source to the selected source group using a CSV file"
      }
      isEditDialogOpen={isOpen}
      setIsEditDialogOpen={setIsOpen}
    >
      <SourceUploadForm
        sourceGroupId={sourceGroupId}
        onClose={() => {
          setIsOpen(false);
          if (onSuccess) {
            onSuccess();
          }
        }}
      />
    </GeneralModal>
  );
};
