/* eslint-disable @typescript-eslint/no-explicit-any */

import AddressCell from "@/components/misc/AddressCell";
import Opfuscate from "@/components/misc/Opfuscate";

export const colsBALA = [
  {
    id: "entry.network",
    name: "Network",
    width: 200,
  },
  {
    id: "entry.asset",
    name: "Asset",
    width: 200,
  },
  {
    id: "entry.address",
    name: "Address",
    width: 400,
  },
];

export const colsEXCH = [
  {
    id: "entry.network",
    name: "Exchange",
    width: 250,
  },
  {
    id: "entry.public",
    name: "Public Key",
    width: 100,
    cell: (val: string) => <AddressCell fullAddress={val} />,
  },
  {
    id: "entry.name",
    name: "Account Name",
    width: 100,
  },
  {
    id: "entry.passphrase",
    name: "Passphrase",
    width: 300,
    cell: (row: string) => <Opfuscate fullText={row} isError={false} />,
  },
];

export const colsPUBK = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entryType",
    name: "Address Type",
    width: 200,
  },
  {
    id: "entry.keys.0",
    name: "xPub Key",
    width: 300,
  },
  {
    id: "entry.keys.length",
    name: "Count",
    width: 150,
  },
];

export const colsRISK = [
  {
    id: "entry.address",
    name: "Address",
    width: 650,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
];

export const colsSIGN = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entry.pubkey",
    name: "Public Key",
    width: 400,
  },
  {
    id: "entry.signature",
    name: "Signature",
    width: 300,
    cell: (row: string) => <AddressCell fullAddress={row} />,
  },
];

export const colsMap: {
  [key: string]: { id: string; name: string; width: number; cell?: any }[];
} = {
  BALA: colsBALA,
  EXCH: colsEXCH,
  PUBK: colsPUBK,
  RISK: colsRISK,
  SIGN: colsSIGN,
};

export const filtersMap: {
  [key: string]: { accessorKey: string; placeholder: string };
} = {
  BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  EXCH: { accessorKey: "entry.network", placeholder: "Filter Exchanges..." },
  PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
};
