/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import NetworkSelect from "./NetworkSelect";
import { ADDRESS_TYPES, BLOCKCHAIN_NETWORKS, EXCHANGE_NETWORKS } from "@/config/general";

interface FormFieldRendererProps {
  fieldType: string;
  control: any;
  sourceType: string;
  assetsForNetwork: any[];
  fetchAssetsForNetwork: (network: string) => Promise<void>;
}

export const FormFieldRenderer: React.FC<FormFieldRendererProps> = ({
  fieldType,
  control,
  sourceType,
  assetsForNetwork,
  fetchAssetsForNetwork
}) => {

  const networksMaster =
    sourceType === "EXCH"
      ? EXCHANGE_NETWORKS
      : ["RISK", "PUBK"].indexOf(sourceType) > -1
        ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "BTC")
        : sourceType === "SIGN"
          ? BLOCKCHAIN_NETWORKS.filter((network) => network.value === "ETH")
          : BLOCKCHAIN_NETWORKS;
  
  switch (fieldType) {
    case "address":
      return (
        <FormField
          control={control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "secret":
      return (
        <FormField
          control={control}
          name="secret"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Secret</FormLabel>
              <FormControl>
                <Input {...field} type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "public":
      return (
        <FormField
          control={control}
          name="public"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Public Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "name":
      return (
        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "private":
      return (
        <FormField
          control={control}
          name="private"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Private Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "passphrase":
      return (
        <FormField
          control={control}
          name="passphrase"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Passphrase</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "key":
      return (
        <FormField
          control={control}
          name="key"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Key/Client ID</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "network":
      return (
          <FormField
            control={control}
            name="network"
            render={({ field }) => {
              const handleNetworkChange = async (newValue: string) => {
                await fetchAssetsForNetwork(newValue);
                field.onChange(newValue);
              };
              return (
                <FormItem className="w-full">
                  <FormLabel>{sourceType === "EXCH" ? "Exchange": "Network"}</FormLabel>
                  <div className="my-4">
                    <NetworkSelect
                      networks={networksMaster}
                      onValueChange={handleNetworkChange}
                      title="Select Network"
                      description="Choose one of the available networks."
                      initialValue={field.value || ""
                      }
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      name={field.name}
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
      );
    case "asset":
      return (
          <FormField
            control={control}
            name="asset"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Asset</FormLabel>
                <div className="my-4">
                  <NetworkSelect
                    networks={assetsForNetwork}
                    onValueChange={field.onChange}
                    title="Select Asset"
                    description="Choose one of the available assets."
                    initialValue={field.value || ""}
                  />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
      );
    case "message":
      return (
        <FormField
          control={control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Message</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "signature":
      return (
        <FormField
          control={control}
          name="signature"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Signature</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "pubkey":
      return (
        <FormField
          control={control}
          name="pubkey"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Public Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "addresstype":
      return (
        <FormField
          control={control}
          name="addresstype"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Address Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select address type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {ADDRESS_TYPES.map((type, i) => (
                    <SelectItem key={i} value={type.value}>
                      {type.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "xpubkey":
      return (
        <FormField
          control={control}
          name="xpubkey"
          render={({ field }) => (
            <FormItem>
              <FormLabel>xPub Key</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    case "numberofaddresses":
      return (
        <FormField
          control={control}
          name="numberofaddresses"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Number of Addresses</FormLabel>
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      );
    default:
      return null;
  }
};