import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useToast } from "@/components/ui/use-toast";
// import { useAuthCheck } from '@/hooks/useAuthCheck';
import useAuthStore from '@/stores/auth.store';

const AuthCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const { toast } = useToast();
  const [user] = useAuthStore((state) => [state.user, state.logout]);

  const handleSessionExpired = useCallback(() => {
    if (location.pathname !== '/login' && location.pathname !== '/auth') {
      // toast({
      //   title: "Session Expired",
      //   description: "Your session has expired. Please log in again.",
      //   variant: "destructive",
      // });
      // logout();
      navigate('/login');
    }
  }, [location.pathname, navigate]);

  // useAuthCheck(handleSessionExpired);

  if (!user && location.pathname !== '/login' && location.pathname !== '/auth') {
    handleSessionExpired();
  }

  return null;
};

export default AuthCheck;