/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createSourceEntry,
  createSourceGroup,
  deleteSourceEntry,
  downloadSourceGroup,
  downloadTemplate,
  editSourceEntry,
  editSourceGroup,
  getAllSourceGroups,
  getSourcesPage,
  selectSourceGroup,
  uploadSource,
} from "../services/sourceService";
import { getNetworks } from "@/services/generalService";
import { ApiTransactionsPayload } from "@/types/report.type";
import {
  NetworksData,
  Source,
  SourceGroup,
  TemplateResponse,
} from "@/types/sources.type";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import useWorkspaceStore from "./workspaces.store";

type SortableSourceField =
  | "sourceName"
  | "groupType"
  | "updated"
  | "sourceId"
  | "sourceGroupId"
  | "count";

type SourcesState = {
  loading: boolean;
  sourceGroups: SourceGroup[];
  sources: Source[];
  clearSourceEntries: () => void;
  networks: NetworksData;
  setNetworks: (networks: NetworksData) => void;
  selectedSourceGroupId: string | null;
  selectedSourceGroup: SourceGroup | null;
  networksLastFetched: number | null;
  source: Source | null;
  setSelectedSourceGroup: (sourceGroupId: string) => void;
  setSelectedSourceGroupData: (sourceGroup: SourceGroup | null) => void;
  setSelectedSource: (source: Source) => void;
  fetchAllSourceGroups: (
    page?: number,
    size?: number
  ) => Promise<ApiTransactionsPayload<SourceGroup>>;
  fetchSourceEntriesForSelectedGroup: (
    sourceGroupId: string | null,
    size?: number,
    page?: number
  ) => Promise<any>;
  uploadSource: (file: string, sourceGroupId: string) => Promise<void>;
  downloadTemplate: (type: string) => Promise<TemplateResponse>;
  createSourceGroup: (
    workspaceId: string,
    sourceName: string,
    groupType: string
  ) => Promise<string>;
  editSourceGroup: (sourceGroupId: string, sourceName: string) => Promise<void>;
  createSourceEntry: (
    sourceGroupId: string | null,
    entryData: any,
    entryType: string
  ) => Promise<void>;
  editSourceEntry: (sourceId: string, entryData: any) => Promise<void>;
  deleteSourceEntry: (sourceId: string) => Promise<void>;
  downloadSourceGroup: (sourceGroupId: string) => Promise<void>;
  selectSourceGroup: (sourceGroupId: string) => Promise<void>;
  fetchNetworks: () => Promise<void>;
  step: number;
  totalPages: number;
  totalRows: number;
  pageSize: number;
  currentPage: number;
  sourcesCurrentPage: number;
  setLoading: (loading: boolean) => void;
  nextStep: () => void;
  prevStep: () => void;
  setCurrentPage: (currentPage: number) => void;
  setSourcesCurrentPage: (currentPage: number) => void;
  setPageSize: (pageSize: number) => void;
  setStep: (step: number) => void;
  sortFields: Array<{ field: SortableSourceField; direction: "asc" | "desc" }>;
  setSortFields: (
    fields: Array<{ field: SortableSourceField; direction: "asc" | "desc" }>
  ) => void;
};

const useSourcesStore = create<SourcesState>()(
  devtools(
    (set, get) => ({
      loading: false,
      networksLastFetched: null,
      sourceGroups: [],
      sources: [],
      source: null,
      step: 1,
      totalPages: 1,
      totalRows: 100,
      pageSize: 100,
      currentPage: 1,
      sourcesCurrentPage: 1,
      networks: {} as NetworksData,
      selectedSourceGroupId: null,
      selectedSourceGroup: null,

      setLoading: (loading: boolean) => set({ loading }),
      clearSourceEntries: () => set({ sources: [] }),
      setNetworks: (networks: NetworksData) => set({ networks }),

      fetchAllSourceGroups: async (page?: number, size?: number) => {
        const defaultPayload: ApiTransactionsPayload<SourceGroup> = {
          data: [],
        };
        set({ loading: true });
        const workspace = useWorkspaceStore.getState().getSelectedWorkspace();
        const workspaceId = workspace?.workspaceId || "";
        if (!workspaceId) {
          set({ loading: false });
          return defaultPayload;
        }
        try {
          const response = await getAllSourceGroups(
            workspaceId,
            size || get().pageSize,
            page || get().currentPage
          );
          set({
            sourceGroups: response.data.data.data,
            totalPages: response.data.data.pages,
            totalRows: response.data.data.max,
            loading: false,
          });
          return { data: response.data.data.data };
        } catch (error) {
          console.error("Error fetching source groups:", error);
          set({ loading: false });
          return defaultPayload;
        }
      },

      setSelectedSourceGroup: async (sourceGroupId) => {
        set({ selectedSourceGroupId: sourceGroupId, loading: true });
        try {
          const entries = await getSourcesPage(
            sourceGroupId,
            get().pageSize,
            get().currentPage
          );
          set({
            sources: entries.data.data,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching source entries:", error);
          set({ loading: false });
        }
      },

      setSelectedSourceGroupData: async (sourceGroup) => {

        set({
          selectedSourceGroup: sourceGroup,
          selectedSourceGroupId: sourceGroup?.sourceGroupId || null,
        });
        await get().fetchSourceEntriesForSelectedGroup(get().selectedSourceGroupId)
      },

      setSelectedSource: (source: Source) => set({ source }),

      uploadSource: async (file, sourceGroupId) => {
        set({ loading: true });
        try {
          await uploadSource(file, sourceGroupId);
          await get().fetchSourceEntriesForSelectedGroup(
            sourceGroupId,
            get().pageSize,
            get().sourcesCurrentPage
          );

        } catch (error) {
          console.error("Error uploading source:", error);
        } finally {
          set({ loading: false });
        }
      },

      fetchSourceEntriesForSelectedGroup: async (
        sourceGroupId: string | null,
        size?: number,
        page?: number
      ) => {

        set({ loading: true });
        if (!sourceGroupId) {
          set({ loading: false });
          return { page, limit: size, pages: 0, max: 0, data: [] };
        }
        try {
          const response = await getSourcesPage(
            sourceGroupId,
            size || get().pageSize,
            page || get().currentPage
          );

          set({
            sources: response.data.data.data,
            totalPages: response.data.pages,
            totalRows: response.data.max,
            loading: false,
          });
          
          return {
            page,
            limit: size,
            pages: response.data.pages || 0,
            max: response.data.max || 0,
            data: response.data.data.data,
          };
        } catch (error) {
          console.error("Error fetching entries:", error);
          set({ loading: false });
          return { page, limit: size, pages: 0, max: 0, data: [] };
        }
      },

      downloadTemplate: async (type) => {
        const response = await downloadTemplate(type);
        return response.data;
      },

      createSourceGroup: async (workspaceId, sourceName, groupType) => {
        set({ loading: true });
        try {
          const response = await createSourceGroup(
            workspaceId,
            sourceName,
            groupType
          );
          const createdSourceGroupId = response && response?.data.sourceGroupId;

        set({
          selectedSourceGroupId: createdSourceGroupId,
        });
          set({
            selectedSourceGroup: {
              sourceGroupId: createdSourceGroupId,
              sourceName,
              groupType,
              count: 0,
              updated: Date.now(),
            },
            loading: false,
          });
          return createdSourceGroupId;
        } catch (error) {
          console.error("Error creating source group:", error);
          set({ loading: false });
          throw error;
        }
      },

      editSourceGroup: async (sourceGroupId, sourceName) => {
        set({ loading: true });
        try {
          await editSourceGroup(sourceGroupId, sourceName);
          await get().fetchAllSourceGroups();
        } catch (error) {
          console.error("Error editing source group:", error);
        } finally {
          set({ loading: false });
        }
      },

      createSourceEntry: async (sourceGroupId, entryData, entryType) => {
        set({ loading: true });
        try {
          await createSourceEntry(sourceGroupId || get().selectedSourceGroupId, entryData, entryType);
          await get().fetchSourceEntriesForSelectedGroup(
            sourceGroupId,
            get().pageSize,
            get().sourcesCurrentPage
          );
        } catch (error) {
          console.error("Error creating source entry:", error);
        } finally {
          set({ loading: false });
        }
      },

      editSourceEntry: async (sourceId, entryData) => {
        set({ loading: true });
        try {
          await editSourceEntry(sourceId, entryData);
          await get().fetchSourceEntriesForSelectedGroup(
            get().selectedSourceGroupId,
            get().pageSize,
            get().sourcesCurrentPage
          );

        } catch (error) {
          console.error("Error editing source entry:", error);
        } finally {
          set({ loading: false });
        }
      },

      deleteSourceEntry: async (sourceId) => {
        set({ loading: true });
        try {
          await deleteSourceEntry(sourceId);
          await get().fetchSourceEntriesForSelectedGroup(
            get().selectedSourceGroupId,
            get().pageSize,
            get().sourcesCurrentPage
          );
        } catch (error) {
          console.error("Error deleting source entry:", error);
        } finally {
          set({ loading: false });
        }
      },

      downloadSourceGroup: async (sourceGroupId) => {
        set({ loading: true });
        try {
          await downloadSourceGroup(sourceGroupId);
        } catch (error) {
          console.error("Error downloading source group:", error);
        } finally {
          set({ loading: false });
        }
      },

      selectSourceGroup: async (sourceGroupId) => {
        set({ loading: true });
        try {
          await selectSourceGroup(sourceGroupId);
        } catch (error) {
          console.error("Error selecting source group:", error);
        } finally {
          set({ loading: false });
        }
      },

      fetchNetworks: async () => {
        const now = Date.now();
        const lastFetched = get().networksLastFetched;
        if (!lastFetched || now - lastFetched > 5 * 60 * 1000) {
          try {
            const response = await getNetworks();
            set({ networks: response.data.data as NetworksData });
          } catch (error) {
            console.error("Error fetching networks:", error);
          }
        }
      },
      nextStep: () => set({ step: Math.min(get().step + 1, 4) }),
      prevStep: () => set({ step: Math.max(get().step - 1, 1) }),
      setStep: (step: number) => set({ step }),
      setCurrentPage: (currentPage: number) => set({ currentPage }),
      setSourcesCurrentPage: (sourcesCurrentPage: number) =>
        set({ sourcesCurrentPage }),
      setPageSize: (pageSize: number) => set({ pageSize }),
      sortFields: [{ field: "updated", direction: "desc" }],
      setSortFields: (fields) => set({ sortFields: fields }),
    }),
    { name: "sources-store" }
  )
);

// Memoized selectors

export const useSources = () =>
  useSourcesStore((state) => {
    const sortedSources = [...state.sources].sort((a, b) => {
      for (const { field, direction } of state.sortFields) {
        const aValue = a[field as keyof Source];
        const bValue = b[field as keyof Source];

        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return direction === "asc" ? -1 : 1;
        if (bValue === undefined) return direction === "asc" ? 1 : -1;

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedSources;
  }, shallow);

export const useSourceGroups = () =>
  useSourcesStore((state) => {
    const sortedSourceGroups = [...state.sourceGroups].sort((a, b) => {
      for (const { field, direction } of state.sortFields) {
        const aValue = a[field as keyof SourceGroup];
        const bValue = b[field as keyof SourceGroup];

        if (aValue === undefined && bValue === undefined) return 0;
        if (aValue === undefined) return direction === "asc" ? -1 : 1;
        if (bValue === undefined) return direction === "asc" ? 1 : -1;

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedSourceGroups;
  }, shallow);

export const useSourcesLoading = () =>
  useSourcesStore((state) => state.loading);

export const useNetworks = () => useSourcesStore((state) => state.networks);
export const useSelectedSourceGroup = () =>
  useSourcesStore((state) => state.selectedSourceGroup, shallow);

export const useSelectedSourceEntries = () =>
  useSourcesStore((state) => state.sources, shallow);
export const usePagination = () =>
  useSourcesStore(
    (state) => ({
      currentPage: state.currentPage,
      sourcesCurrentPage: state.sourcesCurrentPage,
      pageSize: state.pageSize,
      totalPages: state.totalPages,
      totalRows: state.totalRows,
    }),
    shallow
  );

export default useSourcesStore;
