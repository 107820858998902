/* eslint-disable @typescript-eslint/no-explicit-any */
import apiService from "./apiService";

const getPaginationParams = (limit: number, page: number) => {
  return { limit, page };
};

export const getAllSources = async (
  sourceGroupId: string,
  limit: number,
  page: number
) => {
  const params = { sourceGroupId, ...getPaginationParams(limit, page) };
  return apiService.get(`/getSourceEntries`, { params });
};

export const getSourcesPage = async (
  sourceGroupId: string,
  limit: number,
  page: number
) => {
  const params = { sourceGroupId, ...getPaginationParams(limit, page) };
  return apiService.get(`/getSourceEntries`, { params });
};

export const getAllSourceGroups = async (
  workspaceId: string,
  limit: number,
  page: number
) => {
  const params = { workspaceId, ...getPaginationParams(limit, page) };
  return apiService.get(`/getSourceGroups`, { params });
};

export const getSourceGroupsPage = async (
  workspaceId: string,
  limit: number,
  page: number
) => {
  const params = { workspaceId, ...getPaginationParams(limit, page) };
  return apiService.get(`/getSourceGroups`, { params });
};

export const selectSourceGroup = async (sourceGroupId: string) => {
  return apiService.put(`/sourceGroupToReportGroup`, {
    sourceGroupIds: [sourceGroupId],
  });
};

export const downloadSourceGroup = async (sourceGroupId: string) => {
  return apiService.post(`/downloadSource`, { sourceGroupId });
};

export const createSourceGroup = async (
  workspaceId: string,
  sourceName: string,
  groupType: string
) => {
  return apiService.put(`/putSourceGroup`, {
    workspaceId,
    sourceName,
    groupType,
  });
};

export const editSourceGroup = async (
  sourceGroupId: string | null,
  sourceName: string
) => {
  return apiService.patch(`/patchSourceGroup`, {
    sourceGroupId,
    sourceName,
  });
};

export const createSourceEntry = async (
  sourceGroupId: string | null,
  entryData: any,
  entryType: string
) => {
  return apiService.put(`/putSourceEntry`, {
    sourceGroupId,
    entryType,
    entries: [entryData],
  });
};

export const editSourceEntry = async (sourceId: string, entryData: any) => {
  return apiService.patch(`/patchSourceEntry`, {
    sourceId,
    entry: entryData,
  });
};

export const deleteSourceEntry = async (sourceId: string) => {
  return apiService.delete(`/deleteSourceEntry`, {
    data: { sourceIds: [sourceId] },
  });
};

export const uploadSource = async (file: string, sourceGroupId: string) => {
  return apiService.put(`/putSourceEntryCSV`, {
    sourceGroupId,
    csv: file,
  });
};

export const downloadTemplate = async (type: string) => {
  return apiService.get(`/template`, {
    params: { type },
  });
};
