import ErrorBoundary from "@/components/ErrorBoundary";
import { ThemeProvider } from "@/components/theme/ThemeProvider";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { TooltipProvider } from "@/components/ui/tooltip";
import routes from "./routes";
import useSourcesStore, { useNetworks } from "./stores/sources.store";
import { useEffect } from "react";

const App = () => {
  const fetchNetworks = useSourcesStore((state) => state.fetchNetworks);
  const networks = useNetworks();
  const router = createBrowserRouter(routes);

  useEffect(() => {
    if (Object.keys(networks).length === 0) {
      fetchNetworks();
    }
  }, [fetchNetworks, networks]);

  return (
    <ErrorBoundary
      fallback={<div>Oops! Something went wrong. Please try again later.</div>}
    >
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <TooltipProvider>
          <RouterProvider router={router} />
        </TooltipProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;