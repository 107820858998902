import useSourcesStore from "@/stores/sources.store";
import { getNameBySymbol } from "@/lib/utils";

export function DisplayTitle() {

  const { selectedSourceGroup, step } = useSourcesStore();

  const titleData = {
    title: [
      "Source Information",
      `${selectedSourceGroup?.sourceName ? selectedSourceGroup.sourceName : "source"} entries`,
      "Review Source Entries",
      "Finishing up",
    ],
    description: [
      "Please provide your source type and name.",
      `You can add entries individually or upload them in bulk using a CSV file`,
      "Double-check everything looks OK before confirming.",
      "Double-check everything looks OK before confirming.",
    ],
  };

  const maxStep = titleData.title.length;
  return (
    <div
      className={
        step === maxStep
          ? "flex flex-col items-center justify-center gap-5 lg:px-16 p-6 text-left border-b-0"
          : ""
      }
    >
      {/* {step === maxStep && (
        <ThankYouIcon className="w-16 h-16 lg:w-32 lg:h-32" />
      )} */}
      <h3 className="font-semibold tracking-tight text-2xl mt-4 space-y-1.5 text-left">
        {titleData.title[step - 1]}
      </h3>
      <p className="text-sm text-muted-foreground mb-4">
        {titleData.description[step - 1]}
      </p>

      {step > 1  && step < 4 &&  (
        <span className="ml-0 border px-2 rounded-full text-sm text-gray-900 dark:text-gray-300">
          {getNameBySymbol(selectedSourceGroup?.groupType || "")}
        </span>
      )}
    </div>
  );
}
